import axios from "axios"


export interface AutoApplyDetails {
  ticketId: string;
  departureStation: string; // Ändrat från depStation
  arrivalStation: string; // Ändrat från arrivStation
  departureDate: string; // Ändrat från depDate
  departureTime: string; // Ändrat från string till number
  firstName: string;
  lastName: string;
  personalNumber: string; // Ändrat från personNumber
  mobileNumber: string;
  email: string;
}



export async function getUsers() {
  try {
    const response = await axios.get("baseURLusers")

    return response.data
  } catch (error) {
    throw error
  }
}

export async function loginUser(credentials: {
  username: string
  password: string
}) {
  try {
    const response = await axios.post("baseURLlogin", credentials)
    localStorage.setItem("token", response.data)

    return response.data
  } catch (error) {
    throw error
  }
}

const baseURL = process.env.REACT_APP_BASE_URL
// const baseURL = "http://localhost:5001/"
const apiKey = process.env.REACT_APP_TRAIN_INFO_API_KEY
const headerName = process.env.REACT_APP_TRAIN_INFO_HEADER as string

export async function getTrainDetails(
  stationName: string,
  searchDate: string,
 // fromStation: string
) {
  try {
    const url = new URL(baseURL + "stations");
    console.log(stationName)

    if (stationName) url.searchParams.append("stationName", stationName);
   // if (fromStation) url.searchParams.append("fromStation", fromStation);
    if (searchDate) url.searchParams.append("searchDate", searchDate);

    const headers = {
      [headerName]: apiKey,
    };

    const response = await axios.get(url.toString(), { headers });
    console.log(response.data)
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postTrainDetails(autoapplyDetails: AutoApplyDetails) {
  console.log(JSON.stringify(autoapplyDetails))

  try {
    const url = new URL(`${baseURL}autoApply`); 
    const headers = {
      [headerName]: apiKey, 
      "Content-Type": "application/json", 
    };

    const response = await axios.post(url.toString(), autoapplyDetails, {
      headers,
    });

    console.log("Post train details response:", response.data);
    return response.data; 
  } catch (error) {
    console.error("Error posting train details:", error);
    throw error; 
  }
}

