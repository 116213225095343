import React, { useState, useEffect } from "react";
import InfoIcon from '@mui/icons-material/InfoOutlined';
import DeleteForeverSharpIcon from '@mui/icons-material/DeleteForeverSharp';
import { stations } from "../helpers/getStationName";
import { isValidSwedishPersonalNumber } from "../helpers/formatTime";

interface UserDetailsPopupProps {
  onSubmit: (details: {
    firstName: string;
    lastName: string;
    personNumber: string;
    mobileNumber: string;
    email: string;
    ticketId: string;
    departureStation: string;
  }) => void;
  onClose: () => void;
}

const UserDetailsPopup: React.FC<UserDetailsPopupProps> = ({ onSubmit, onClose }) => {
  const [details, setDetails] = useState({
    firstName: "",
    lastName: "",
    personNumber: "",
    mobileNumber: "",
    email: "",
    ticketId: "",
    departureStation: "",
  });

  const [error] = useState<string | null>(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [departureStation, setDepartureStation] = useState(() => {
    return localStorage.getItem("departureStation") || ""; // Hämta från localStorage eller sätt till en tom sträng
  });
  const [suggestions, setSuggestions] = useState<string[]>([]); // För filtrerade förslag
  const [showDropdown, setShowDropdown] = useState(false); // För att visa hela listan


  const handleStationNameChange = (value: string) => {
    if (!value) {
      setDepartureStation("");
      setSuggestions([]);
      console.log(departureStation)
      return;
    }

    const formattedValue = value.charAt(0).toUpperCase() + value.slice(1);
    setDepartureStation(formattedValue);

    const filteredSuggestions = stations.filter((station) =>
      station.toLowerCase().startsWith(formattedValue.toLowerCase())
    );
    setSuggestions(filteredSuggestions);
  };

  const handleSuggestionClick = (suggestion: string) => {
    setDepartureStation(suggestion);
    setSuggestions([]);


    const updatedDetails = { ...details, departureStation: suggestion };
    setDetails(updatedDetails);
    localStorage.setItem("userDetails", JSON.stringify(updatedDetails));
  };


  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };


  useEffect(() => {
    const savedDetails = localStorage.getItem("userDetails");
    if (savedDetails) {
      setDetails(JSON.parse(savedDetails));
    }
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (name === "personNumber") {
      // Lägg till "-" automatiskt efter 8 tecken
      let formattedValue = value.replace(/\D/g, ""); // Ta bort allt som inte är en siffra
      if (formattedValue.length > 8) {
        formattedValue = `${formattedValue.slice(0, 8)}-${formattedValue.slice(8)}`;
      }
      setDetails((prevDetails) => ({
        ...prevDetails,
        personNumber: formattedValue,
      }));
      return;
    }
    setDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (error) return;
    onSubmit(details);
    localStorage.setItem("userDetails", JSON.stringify(details));
  };

  const handleDelete = () => {
    localStorage.removeItem("userDetails");
    setShowConfirmModal(false);
    onClose();
  };

  const isPersonNumberValid = (): boolean => {
    const regex = /^[0-9]{8}-[0-9]{4}$/;
    return regex.test(details.personNumber);
  };

  const isFormValid = (): boolean => {
    console.log(details.ticketId)
    return (
      details.firstName.trim() !== "" &&
      details.lastName.trim() !== "" &&
      isPersonNumberValid() &&
      details.mobileNumber.trim() !== "" &&
      details.email.trim() !== "" &&
      details.ticketId.trim() !== "" &&
      details.departureStation.trim() !== ""
    );
  };



  return (
    <div className="modal show d-block" style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <InfoIcon
              onClick={() => setShowInfoModal(true)}
            />
            <h5 className="modal-title text-center flex-grow-1">Användaruppgifter</h5>
            <button type="button" className="btn-close" onClick={onClose}></button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="firstName" className="form-label">
                  Förnamn
                </label>
                <input
                  type="text"
                  name="firstName"
                  id="firstName"
                  placeholder="Förnamn"
                  className="form-control"
                  value={details.firstName}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="lastName" className="form-label">
                  Efternamn
                </label>
                <input
                  type="text"
                  name="lastName"
                  id="lastName"
                  placeholder="Efternamn"
                  className="form-control"
                  value={details.lastName}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-3 position-relative">
                <label htmlFor="departureStation" className="form-label">
                  Avgångsort
                </label>
                <div className="input-group">
                  <input
                    type="text"
                    id="departureStation"
                    className="form-control"
                    placeholder="Sök station"
                    value={details.departureStation}
                    onChange={(e) => handleStationNameChange(e.target.value)}
                    required
                  />
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={toggleDropdown}
                  >
                    ▼
                  </button>
                </div>
                {/* Visa filtrerade förslag */}
                {suggestions.length > 0 && (
                  <ul className="list-group position-absolute w-100">
                    {suggestions.map((suggestion, index) => (
                      <li
                        key={index}
                        className="list-group-item list-group-item-action"
                        onClick={() => handleSuggestionClick(suggestion)}
                        style={{ cursor: "pointer" }}
                      >
                        {suggestion}
                      </li>
                    ))}
                  </ul>
                )}
                {/* Visa hela station-listan */}
                {showDropdown && (
                  <div
                    className="modal show d-block"
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
                    role="dialog"
                    aria-labelledby="dropdownModalTitle"
                    aria-hidden={!showDropdown}
                  >
                    <div className="modal-dialog modal-dialog-centered">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 id="dropdownModalTitle" className="modal-title">Välj station</h5>
                          <button
                            type="button"
                            className="btn-close"
                            onClick={() => setShowDropdown(false)}
                            aria-label="Stäng"
                          ></button>
                        </div>
                        <div className="modal-body">
                          <ul className="list-group">
                            {stations.map((station, index) => (
                              <li
                                key={index}
                                className="list-group-item list-group-item-action"
                                onClick={() => {
                                  setDepartureStation(station);
                                  setShowDropdown(false); // Stäng modalen när en station valts
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                {station}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

              </div>

              <div className="mb-3">
                <label htmlFor="personNumber" className="form-label">
                  Personnummer
                </label>
                <input
                  type="text"
                  name="personNumber"
                  id="personNumber"
                  placeholder="YYYYMMDD-XXXX"
                  className={`form-control ${details.personNumber && !isValidSwedishPersonalNumber(details.personNumber)
                      ? "is-invalid"
                      : ""
                    }`}
                  value={details.personNumber}
                  onChange={handleChange}
                  required
                />
                {details.personNumber && !isValidSwedishPersonalNumber(details.personNumber) && (
                  <div className="invalid-feedback">
                    Ogiltigt personnummer. Kontrollera format och siffror.
                  </div>
                )}
              </div>
              <div className="mb-3">
                <label htmlFor="mobileNumber" className="form-label">
                  Telefonnummer
                </label>
                <input
                  type="text"
                  name="mobileNumber"
                  id="mobileNumber"
                  placeholder="0700000000"
                  className="form-control"
                  value={details.mobileNumber}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  E-postadress
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="janeDoe@example.com"
                  className="form-control"
                  value={details.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="ticketId" className="form-label">
                  Movingo biljettnummer
                </label>
                <input
                  type="text"
                  name="ticketId"
                  id="ticketId"
                  placeholder="Enbart Movingo"
                  className="form-control"
                  value={details.ticketId}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="d-flex justify-content-between">
                <DeleteForeverSharpIcon onClick={() => setShowConfirmModal(true)} />
                <div>
                  <button type="button" className="btn btn-secondary me-2" onClick={onClose}>
                    Avbryt
                  </button>
                  <button disabled={!isFormValid()} type="submit" className="btn btn-primary">
                    Spara
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      {showConfirmModal && (
        <div className="modal show d-block" style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Radera uppgifter?</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowConfirmModal(false)}
                ></button>
              </div>
              <div className="modal-body">
                <p>Du kommer inte längre kunna använda vår automatiska tjänst om du raderar dina uppgifter.</p>
                <p>Är du säker på att du vill radera dina uppgifter?</p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setShowConfirmModal(false)}
                >
                  Avbryt
                </button>
                <button type="button" className="btn btn-danger" onClick={handleDelete}>
                  Ja, radera
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {showInfoModal && (
        <div className="modal show d-block" style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Information om dina uppgifter</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowInfoModal(false)}
                ></button>
              </div>
              <div className="modal-body">
                <p>
                  Dina uppgifter sparas bara på din enhet och inte på våra servrar. Du har full kontroll över informationen, och allt lagras och hanteras lokalt hos dig.
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  disabled={!details.firstName && !details.lastName && !details.email && !details.mobileNumber && !details.personNumber && !details.ticketId}
                  className="btn btn-secondary"
                  onClick={() => setShowInfoModal(false)}
                >
                  Stäng
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserDetailsPopup;
