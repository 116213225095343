import React, { useState } from "react"
import { Link } from "react-router-dom"
import UserDetailsPopup from "./userDetails";
import InfoIcon from '@mui/icons-material/InfoSharp';
import AccountCircleSharpIcon from '@mui/icons-material/AccountCircleSharp';

const Navbar = () => {
  const [showModal, setShowModal] = useState(false)
  const [showPopup, setShowPopup] = useState(false);


  /*
    const isAuthenticated = Boolean(localStorage.getItem("token"))
  const navigate = useNavigate()
    const [username, setUsername] = useState("")
  useEffect(() => {
    if (isAuthenticated) {
      const fetchUserDetails = async () => {
        try {
          const userDetailsResponse = await getUserDetails()
          console.log(userDetailsResponse)
          setUsername(userDetailsResponse || "Gäst")
        } catch (error) {
          console.error("Error fetching user details:", error)
        }
      }

      fetchUserDetails()
    } else {
      setUsername("Gäst")
    }
  }, [isAuthenticated])

  const handleLogout = async () => {
    try {
      await logoutUser()
      localStorage.removeItem("token")
      navigate("/")
    } catch (error) {
      console.error("Error logging out:", error)
    }
  }

  */
  const toggleModal = () => setShowModal(!showModal)

  const handlePopupSubmit = (details: {
    firstName: string;
    lastName: string;
    personNumber: string;
    mobileNumber: string;
    email: string;
    ticketId: string;
  }) => {
    localStorage.setItem("userDetails", JSON.stringify(details));
    setShowPopup(false);
  };

  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            <img
              src="/bgimg-removebg-preview.png"
              alt="Bilden kunde inte laddas"
              width={100}
            />
          </Link>
          <div className="ms-auto d-flex gap-2">
            {/** Change this to true when its working */}
            <AccountCircleSharpIcon style={{ color: "white" }} onClick={() => setShowPopup(false)} />
            <InfoIcon style={{ color: "white" }} onClick={toggleModal} />
          </div>
        </div>
      </nav>

      <div
        className={`modal ${showModal ? "show" : ""}`}
        tabIndex={-1}
        style={{ display: showModal ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Info om tjänsten</h5>
              <button
                type="button"
                className="btn-close"
                onClick={toggleModal}
              ></button>
            </div>
            <div className="modal-body">
              <p>
                Användarvillkor för Mälardalens trafik reseersättning
                <br />
                <br />
                Välkommen till MTRE!
                <br />
                Den här tjänsten är helt gratis för dig att använda, och vi finansierar den genom annonser på vår hemsida.
                För att kunna fortsätta erbjuda tjänsten utan kostnad behöver vi din hjälp – vänligen stäng av din adblocker.
                Tack för att du stödjer oss!
                <br />
                Vi tillhandahåller en tjänst som syftar till att underlätta för
                resenärer i Mälardalen genom att tillhandahålla uppdaterad
                information om tågförseningar.
                <br />
                <br />
                Tjänsten är avsedd att fungera som en guide för att hjälpa dig
                hitta information om försenade tåg i Mälardalen och underlätta
                processen att ansöka om ersättning vid förseningar.
                <br />
                <br />
                1. Accept av Villkor
                <br />
                Genom att använda MTRE, godkänner du dessa användarvillkor. Om
                du inte godkänner villkoren, bör du inte använda vår tjänst.
                <br />
                <br />
                2. Tjänstens Natur och Användning <br />
                Tjänsten är gratis och kräver ingen inloggning. För närvarande
                är tjänsten endast tillgänglig för resenärer inom Mälardalen.
                MTRE tillhandahåller information om tågförseningar baserat på
                tillgängliga data. Användarna uppmanas att använda informationen
                som en guide och alltid dubbelkolla tågtiderna från flera källor
                för att få de mest exakta uppdateringarna.
                <br />
                <br />
                3. Ansvarsfriskrivning
                <br />
                Medan MTRE strävar efter att tillhandahålla korrekt och aktuell
                information, kan det finnas fall där information om
                tågförseningar kan skilja sig någon minut. Vi tar inget ansvar
                för eventuella felaktigheter i den tillhandahållna informationen
                eller för några förluster eller skador som uppstår som ett
                resultat av att förlita sig på denna information. MTRE är inte
                ansvarig för innehållet på externa webbplatser som kan länkas
                till från vår tjänst.
                <br />
                <br />
                4. Ändringar i Tjänsten och Villkoren
                <br />
                MTRE förbehåller sig rätten att när som helst ändra eller
                avbryta tjänsten utan förvarning. Vi förbehåller oss även rätten
                att ändra dessa användarvillkor. Eventuella ändringar träder i
                kraft när de publiceras på vår webbplats.
                <br />
                <br />
                5. Integritetspraxis Eftersom tjänsten är gratis och inte kräver
                någon inloggning, samlar vi inte in personlig information från
                våra användare. Genom att använda MTRE accepterar du dessa
                villkor i sin helhet.
                <br />
                <br />
                Kom ihåg att alltid dubbelkolla tågtiderna från pålitliga källor
                för att försäkra dig om att du har den mest exakta och
                uppdaterade informationen. All vår data kommer från
                Trafikverket.
              </p>
              <p>
                <br />
                6. Användning av cookies och analysverktyg
                <br />
                MTRE använder cookies för att förbättra din användarupplevelse och för att analysera trafik till vår webbplats med hjälp av Google Analytics.
                Cookies är små textfiler som lagras på din enhet och används för att samla in information om hur du använder vår tjänst. Denna information
                används för att förstå besöksmönster och förbättra tjänsten.
                <br />
                <br />
                Genom att använda vår webbplats godkänner du att cookies används enligt vår policy. Om du inte vill att cookies används kan du
                stänga av dem i din webbläsares inställningar. Observera att vissa funktioner på webbplatsen kan påverkas om cookies inaktiveras.
              </p>

            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={toggleModal}
              >
                Stäng
              </button>
            </div>
          </div>
        </div>
      </div>
      {showPopup && (
        <UserDetailsPopup
          onSubmit={handlePopupSubmit}
          onClose={() => setShowPopup(false)}
        />
      )}
    </div>
  )
}

export default Navbar

/*


  <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavAltMarkup"
            aria-controls="navbarNavAltMarkup"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>

              , <span>{username}</span>
                  <Link className="navbar-brand" to="/admin">
                admin
              </Link>

              
              {isAuthenticated ? (
                <button onClick={handleLogout} className="btn btn-danger">
                  Logga ut
                </button>
              ) : (
                <Link to="/login" className="btn btn-success">
                  Logga in
                </Link>
              )}
              
              
              */
