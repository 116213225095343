 export const getStationName = (code: string) => {
    return stationNames[code.toUpperCase()] || code;
  };


   export const stationNames: { [key: string]: string } = {
    "ET": "Eskilstuna",
    "CST": "Stockholm",
    "U": "Uppsala",
    "ÖR": "Örebro",
    "ARB": "Arboga",
    "KÖR": "Kungsör",
    "SGS": "Strängnäs",
    "LG": "Läggesta",
    "NKV": "Nykvarn",
    "SÖÖ": "Södertälje",
    "FLB": "Flemingsberg",
    "ARNC": "Arlanda",
    "MR": "Märsta",
    "KN": "Knivsta",
    "SL": "Sala",
    "RN": "Ransta",
    "VÅ": "Västerås",
    "KBÄ": "Kolbäck",
    "KSU": "Kvicksund",
    "HNÄ": "Hälleforsnäs",
    "FLE": "Flen",
    "K": "Katrineholm",
    "NR": "Norrköping",
    "LP": "Linköping",
    "ÖB": "Örebro södra",
    "VR": "Vingåker",
    "HPBG": "Hallsberg",
  };
  

  export const stations = [
    "Eskilstuna",
    "Stockholm",
    "Uppsala",
    "Örebro",
    "Arboga",
    "Kungsör",
    "Strängnäs",
    "Läggesta",
    "Nykvarn",
    "Södertälje",
    "Flemingsberg",
    "Arlanda",
    "Märsta",
    "Knivsta",
    "Sala",
    "Ransta",
    "Västerås",
    "Kolbäck",
    "Kvicksund",
    "Hälleforsnäs",
    "Flen",
    "Katrineholm",
    "Norrköping",
    "Linköping",
  ];