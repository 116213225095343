 export const formatTime = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = {
      hour: "2-digit",
      minute: "2-digit",
    };
    return new Date(dateString).toLocaleTimeString("sv-SE", options);
  };

  export function isValidSwedishPersonalNumber(personNumber: string): boolean {
    const regex = /^[0-9]{8}-[0-9]{4}$/; // Validera formatet YYYYMMDD-XXXX
    if (!regex.test(personNumber)) {
      return false;
    }
  
    // Ta bort bindestreck för Luhn-algoritmen
    const cleanedNumber = personNumber.replace("-", "");
  
    // Använd endast de 10 sista siffrorna för validering
    const shortNumber = cleanedNumber.slice(-10);
  
    const digits = shortNumber.split("").map(Number);
    const controlDigit = digits.pop(); // Sista siffran är kontrollsiffran
  
    const sum = digits
      .reverse()
      .map((digit, index) => {
        const weight = index % 2 === 0 ? 2 : 1; // Varannan siffra multipliceras med 2
        const result = digit * weight;
        return result > 9 ? result - 9 : result; // Om resultatet > 9, subtrahera 9
      })
      .reduce((acc, curr) => acc + curr, 0);
  
    const calculatedControlDigit = (10 - (sum % 10)) % 10;
  

    return controlDigit === calculatedControlDigit;
  }
  